<template>
	<v-row no-gutters>
		<v-col cols="12">
			<v-tabs v-model="tab" grow>
				<v-tab>Balanço 2023</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab">
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<iframe
								title="Estoque"
								style="height:90vh; width:100%"
								src="https://app.powerbi.com/view?r=eyJrIjoiODJlOTgxNWYtNWViNi00Y2VlLTlmZTEtNDMxNjg2ZGM4YWMwIiwidCI6ImM0ZTVmMWUyLWIyNTktNDE3NC04NTIyLWM0OGZiNmI5ZTAzNyJ9"
								frameborder="0"
								allowfullscreen="true"
							></iframe>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "GPPDashboard",
	components: {},
	data: () => ({
		tab: 0,
	}),
	computed: {},
	methods: {
		async init() {},
	},
	created() {
		this.init();
	},
};
</script>